import gql from 'graphql-tag'

const detailResponse = `
  id type code name isActive parentId
  reviewedAt reviewedUser {id name}
  approvedAt approvedUser {id name}
  parent {
    id type code name
  }
  topics {
    id documentActionRequestId revision effectiveDate remark
    documentActionRequest {id code name}
    users {
      type distributedAt distributedBy distributedUser {id name}
      returnedAt returnedBy returnedUser {id name}
    }
  }
`

export const LIST_PAPER = (templateType) => gql`query LIST_PAPER ($paperType: String!, $q: FilterInput) {
  papers: list${templateType} (paperType: $paperType, q: $q) {
    id type code name isActive
    approvedBy
  }
}`

export const DETAIL_PAPER = (templateType) => gql`query DETAIL_PAPER ($paperType: String!, $paperId: Int!) {
  paper: detail${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const CREATE_PAPER = (templateType) => gql`mutation CREATE_PAPER ($paperType: String!, $input: ${templateType}Input!) {
  paper: create${templateType} (paperType: $paperType, input: $input) {${detailResponse}}
}`

export const UPDATE_PAPER = (templateType) => gql`mutation UPDATE_PAPER ($paperType: String!, $paperId: Int!, $input: ${templateType}Input!) {
  paper: update${templateType} (paperType: $paperType, paperId: $paperId, input: $input) {${detailResponse}}
}`

export const APPROVE_SUBMIT_PAPER = (templateType) => gql`mutation APPROVE_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: approveSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const APPROVE_UNDO_PAPER = (templateType) => gql`mutation APPROVE_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: approveUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const REVIEW_SUBMIT_PAPER = (templateType) => gql`mutation REVIEW_SUBMIT_PAPER ($paperType: String!, $paperId: Int!, $submitDate: Date!) {
  paper: reviewSubmit${templateType} (paperType: $paperType, paperId: $paperId, submitDate: $submitDate) {${detailResponse}}
}`

export const REVIEW_UNDO_PAPER = (templateType) => gql`mutation REVIEW_UNDO_PAPER ($paperType: String!, $paperId: Int!) {
  paper: reviewUndo${templateType} (paperType: $paperType, paperId: $paperId) {${detailResponse}}
}`

export const DESTROY_PAPER = (templateType) => gql`mutation DESTROY_PAPER ($paperType: String!, $paperId: Int!) {
  paper: destroy${templateType} (paperType: $paperType, paperId: $paperId) {id}
}`

export const PAPER_DARS = (templateType) => gql`query PAPER_DARS ($paperType: String!, $q: FilterInput) {
  papers: list${templateType}DocumentActionRequest (paperType: $paperType, q: $q) {
    id code name
  }
}`

export const PAPER_PAPERS = (templateType) => gql`query PAPER_PAPERS ($paperType: String!, $q: FilterInput) {
  papers: list${templateType}Paper (paperType: $paperType, q: $q) {
    id code name
  }
}`
